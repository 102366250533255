import bank_logo from '@assets/images/logo-bank-bri.png';

export const BRIDE = 'Irmayanti';
export const GROOM = 'Herwin';

export const META_INFO = {
  description: `Dengan memohon Rahmat dan Ridho Illahi, teriring niat menjalankan Sunnah Rasulullah ﷺ untuk membentuk rumah tangga yang Sakinah, Mawaddah wa Rahmah, kami mohon do'a agar senantiasa diberikan kelancaran dan keberkahan. - Irmayanti & Herwin`,
  title: `The Wedding of ${BRIDE} & ${GROOM}`,
};

export const GOOGLE_MAPS = {
  title: `Google Maps - ${BRIDE} & ${GROOM} Wedding Party`,
  locations: [
    {
      title: 'Akad Nikah & Resepsi',
      link: 'https://maps.app.goo.gl/WkxRpmSvvcQWrS6EA',
      iframe: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3971.1601410786957!2d120.12103099999999!3d-5.543258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNcKwMzInMzUuNyJTIDEyMMKwMDcnMTUuNyJF!5e0!3m2!1sen!2sid!4v1718415337584!5m2!1sen!2sid',
      description: 'Dusun Batulonggae, Desa Bontomasila <br/>Kabupaten Bulukumba, Sulawesi Selatan 92561',
    },
    {
      title: 'Resepsi 2',
      link: 'https://maps.app.goo.gl/EQSqLiDRCj9Kd5Ya7',
      iframe: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d992.7502725918187!2d120.15795592852477!3d-5.5668529996493845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNcKwMzQnMDAuNyJTIDEyMMKwMDknMzEuMCJF!5e0!3m2!1sen!2sid!4v1718419623323!5m2!1sen!2sid',
      description: 'Jl. A. Sirajuddin, Togambang Matekko <br/>Kabupaten Bulukumba, Sulawesi Selatan 92561',
    },
  ],
};

export const GOOGLE_CALENDAR_LINK = `https://calendar.google.com/calendar/event?action=TEMPLATE&dates=20240625T020000Z%2F20231125T083000Z&text=${BRIDE}+%26+${GROOM}+Wedding&details=${BRIDE}+%26+${GROOM}+Wedding`;

export const PREWEDDING = {
  video: '', // URL video here
  title: `Pre-Wedding ${BRIDE} & ${GROOM}`,
};

export const BANK_INFO = {
  logo: bank_logo,
  name: 'Bank Mandiri',
  accountNumber: '490101031848536',
  accountName: 'Irmayanti',
};

export const FAMILY_INFO = {
  brideParent: 'Bapak Juddin <br />& Ibu Supi',
  groomParent: 'Bapak Rudi (Alm) <br />& Ibu Faidah (Almh)',
  enablePrimaryInvite: false,
  primaryInvite: 'Hamidah Arafah & Ikhwan Alimuddin (Alm)',
  families: [
    'Kel. Besar Arafah Badening',
    'Kel. Besar H. M. Arafah Bolong',
    'Kel. Besar Alimuddin Tjanda (Alm)',
  ],
};

export const EVENT_INFO = {
  host: 'https://irma-herwin.sibunglon.com',
  for: 'both', // one or both
  project: '#TheIrmaErwin',
  epochStart: 1719280800, // Tuesday, June 25, 2024 10:00:00 AM GMT+08:00
  epochEnd: 1719324000, // Tuesday, June 25, 2024 10:00:00 PM GMT+08:00
  utcWeddingTime: '2024-06-25:02:00:00Z', // Tuesday, June 25, 2024 10:00:00 AM GMT+08:00
  weddingGuestInfo: [
    {
      title: 'Akad Nikah & Resepsi',
      time: '10.00 WITA - Selesai',
      date: 'Selasa, 25 Juni 2024',
      description: 'Dusun Batulonggae <br/>Desa Bontomasila <br/>Bulukumba, Sulawesi Selatan',
    },
    {
      title: 'Resepsi 2',
      time: '13.00 WITA - Selesai',
      date: 'Selasa, 25 Juni 2024',
      description: 'Jl. A. Sirajuddin <br/>Togambang Matekko <br/>Bulukumba, Sulawesi Selatan',
    },
  ],
  weddingInvitationInfo: [
    {
      title: 'Akad Nikah & Resepsi',
      time: 'Akan dilaksanakan pagi hari',
      date: 'Selasa, 25 Juni 2024',
      description: 'Dusun Batulonggae <br/>Desa Bontomasila <br/>Bulukumba, Sulawesi Selatan',
    },
    {
      title: 'Resepsi 2',
      time: 'Akan dilaksanakan siang hari',
      date: 'Selasa, 25 Juni 2024',
      description: 'Jl. A. Sirajuddin <br/>Togambang Matekko <br/>Bulukumba, Sulawesi Selatan',
    },
  ],
};

export const SETTINGS = {
  enableStory: false,
  enableWishes: false,
  instagramLive: '', // sibunglon.ganteng
};
